import "@hotwired/turbo-rails";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { Francais } from "flatpickr/dist/l10n/fr.js";
import gsap from "gsap";

import "./controllers";
import { addClickListener } from "./utils/events";
import { remToPx } from "./utils/sizing";

import "../../libs/turbo";

class Application {
  constructor() {
    this.$loader = document.body.querySelector(".loader");
  }

  addEventListeners() {
    flatpickr.localize(Francais);
    flatpickr(".datepicker", { locale: "fr" });
  }

  afterRender() {
    document.body.classList.remove("page--loading");
    document.body.classList.remove("scroll-none");
    document.body.classList.remove("--popup-opened");
    document.body.classList.add("page--ready");
    const clickedFlavour = document.querySelector(".flavour-link.clicked");
    if (clickedFlavour) {
      clickedFlavour.classList.remove("clicked");
      clickedFlavour.classList.add("group/pastille");
    }
    this.addEventListeners();
    this.playVideos();
  }

  beforeRender(event) {
    event.preventDefault();

    const loadTime = (event.timeStamp - this.leaveTime) / 1000;
    const delayDuration = loadTime < 0.5 ? 0.5 - loadTime : 0;
    document.body.classList.remove("--burger-opened");

    event.detail.newBody.classList.remove("page--leaving");

    this.detectSafari(event.detail.newBody);

    gsap.delayedCall(
      delayDuration,
      function () {
        event.detail.resume();
      }.bind(this),
    );
  }

  beforeVisit(event) {
    if (this.clickedFlavour != null) {
      document.body.classList.add("page--flavour");
      this.clickedFlavour.classList.add("clicked");
      this.clickedFlavour.classList.remove("group/pastille");
    }
    document.body.classList.remove("--burger-opened");
    document.body.classList.add("page--loading");
    document.body.classList.add("page--leaving");
    document.body.classList.remove("page--ready");
    document.body.classList.remove("disable-animations");

    this.leaveTime = event.timeStamp;
  }

  detectSafari(body) {
    const UA = window.navigator.userAgent;
    const isWebkit =
      /\b(iPad|iPhone|iPod|Macintosh)\b/.test(UA) &&
      /WebKit/.test(UA) &&
      !/Edge/.test(UA) &&
      !/Chrome/.test(UA) &&
      !window.MSStream;

    if (isWebkit) {
      body.classList.add("safari");
    }
  }

  init() {
    this.detectSafari(document.body);

    flatpickr.localize(Francais);
    flatpickr(".datepicker", { locale: "fr" });

    addClickListener(document.documentElement, 'a[href*="#"]', this.handleSmoothScrollTo.bind(this));
    addClickListener(document.documentElement, "a.flavour-link", this.onClickFlavour.bind(this));

    this.$loader.classList.add("active");
    document.body.classList.remove("page--loading");
    document.addEventListener("turbo:load", this.onLoad.bind(this));
    document.addEventListener("turbo:before-render", this.beforeRender.bind(this));
    document.addEventListener("turbo:before-visit", this.beforeVisit.bind(this));
    document.addEventListener("turbo:render", this.afterRender.bind(this));
  }

  onClickFlavour(event) {
    this.clickedFlavour = event.target.closest("a");
  }

  onLoad(event) {
    if (this.$loader.classList.contains("active")) {

      const loadTime = event.timeStamp / 1000;
      this.removeLoader(loadTime);
    }
  }

  handleSmoothScrollTo(event) {
    const link = event.target.closest("a");
    if (!link) return;

    const hash = link.getAttribute("href").split('#')[1];
    const pageUrl = link.getAttribute("href").split('#')[0];

    if (pageUrl != "" && pageUrl != window.location.origin + window.location.pathname) return;

    const target = document.querySelector("#" + hash);
    if (!(target instanceof Element)) return;

    window.scrollTo({
      top: target.getBoundingClientRect().top + window.scrollY - remToPx(15),
      behavior: "smooth",
    });

    event.preventDefault();
  }

  playVideos() {
    document.querySelectorAll("video[autoplay]").forEach((video) => {
      video.play();
    });
  }

  async removeLoader(loadTime) {
    if (import.meta.env.DEV) {
      this.$loader.classList.remove("active");
      document.querySelector(".loader").remove();
      document.body.classList.remove("scroll-none");
      document.body.classList.add("page--ready");
      this.addEventListeners();
      return;
    }

    const delayDuration = loadTime < 1.5 ? 1.5 - loadTime : 0;
    let tl = gsap.timeline();

    tl.to(this.$loader.querySelector(".red"), {
      duration: delayDuration + 0.9,
      ease: "power1.out",
      transform: "scale(1.03)",
    })
      .to(
        this.$loader.querySelector(".beige"),
        { duration: delayDuration + 0.9, ease: "power1.out", transform: "scale(1.03)" },
        "<",
      )
      .to(
        this.$loader.querySelector(".beige-transparent"),
        { duration: delayDuration + 0.9, ease: "power1.out", transform: "scale(1.03)" },
        "<",
      )
      .to(
        this.$loader.querySelector(".white-dots"),
        { duration: delayDuration + 0.9, ease: "power1.out", transform: "scale(1.035)" },
        "<",
      )
      .to(
        this.$loader.querySelector(".color-dots"),
        { duration: delayDuration + 0.9, ease: "power1.out", transform: "scale(1.035)" },
        "<",
      )
      .to(
        this.$loader.querySelector(".leaves"),
        { duration: delayDuration + 0.9, ease: "power1.out", transform: "scale(1.04)" },
        "<",
      )
      .to(
        this.$loader.querySelector(".beans"),
        { duration: delayDuration + 0.9, ease: "power1.out", transform: "scale(1.04)" },
        "<",
      )
      .to(
        this.$loader.querySelector(".brown-waves"),
        { duration: delayDuration + 0.9, ease: "power1.out", transform: "scale(1.042)" },
        "<",
      )
      .to(
        this.$loader.querySelector(".branch"),
        { duration: delayDuration + 0.9, ease: "power1.out", transform: "scale(1.04)" },
        "<",
      )
      .to(this.$loader, { delay: delayDuration, duration: 0.8, opacity: 0 }, "<")
      .then(() => {
        this.$loader.classList.remove("active");
        document.querySelector(".loader").remove();
      });
    this.addEventListeners();
    gsap.delayedCall(
      delayDuration + 0.1,
      function () {
        document.body.classList.remove("scroll-none");
        document.body.classList.add("page--ready");
        if (window.location.hash) {
          const target = document.querySelector(window.location.hash);
          if (target instanceof Element) {
            window.scrollTo({
              top: target.getBoundingClientRect().top + window.scrollY - remToPx(15),
              behavior: "smooth",
            });
          }
        }
      }.bind(this),
    );
  }
}

const app = new Application();
app.init();
