import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";

// Connects to data-controller="header"
export default class extends Controller {
  static targets = [
    "headerElement",
    "wrapper",
    "mainMenu",
    "submenuToggle",
    "submenus",
    "submenu",
    "background",
    "logo",
    "burgerMenu",
    "scrollAnimationWrapper",
  ];

  closeBurger() {
    document.body.classList.remove("--burger-opened");
    gsap.delayedCall(0.4, () => {
      this.burgerMenuTarget.classList.remove("fade-in--manual", "fade-in-childs--manual");
      this.burgerMenuTarget.querySelectorAll('[data-accordeon-group-target="accordeons"]:checked').forEach((item) => {
        item.checked = false;
      });
    });
  }

  connect() {
    this.closeBurger();
    this.closeSubmenus(true);
    this.dispatch("closeOrderPopup");

    this.currentSubmenu = null;
    this.currentSubmenuToggle = null;
    if (window.innerWidth > 1023) {
      this.createScroll();
    }

    const popupButtons = document.querySelectorAll('.open-order-popup, a[href*="#open-order-popup"]');

    popupButtons.forEach((element) => {
      element.addEventListener("click", (event) => {
        event.preventDefault();
        this.openOrderPopup();
      });
    });
  }

  createScroll() {
    this.headerTl = gsap.timeline({});
    this.headerTl
      .to(this.logoTarget, {
        duration: 0.45,
        ease: "power2.inOut",
        scale: 0.859,
      })
      .to(this.scrollAnimationWrapperTarget, { duration: 0.45, ease: "power2.inOut", y: "-1rem" }, "<");

    this.observer = new IntersectionObserver(
      (entries, _observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.headerTl.play();
          } else if (entry.boundingClientRect.y > 0) {
            this.headerTl.reverse();
          }
        });
      },
      {
        root: null,
        rootMargin: "500px 0px 0px 0px",
        threshold: 0,
      },
    );
    this.observer.observe(document.querySelector("#intersectionTarget"));
  }

  disconnect() {
    this.closeSubmenus();
    this.closeBurger();

    if (this.headerTl) {
      this.headerTl.kill();
    }
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  onResize() {
    if (this.currentSubmenu) {
      this.closeSubmenus();
    } else {
      gsap.to(this.wrapperTarget, { delay: 0.1, duration: 0.25, height: this.mainMenuTarget.offsetHeight });
    }
    this.closeBurger();
    if (this.headerTl) {
      this.headerTl.reverse();
      this.headerTl.kill();
    }
    if (this.observer) {
      this.observer.disconnect();
    }
    if (window.innerWidth > 1023) {
      this.createScroll();
    }
  }

  openBurger() {
    document.body.classList.add("--burger-opened");
    gsap.delayedCall(0.4, () => {
      this.burgerMenuTarget.classList.add("fade-in--manual", "fade-in-childs--manual");
    });
  }

  openOrderPopup() {
    this.dispatch("openOrderPopup");
  }
  onBackgroundClick(event) {
    this.closeSubmenus();
  }
  closeSubmenus(immediate = false) {
    this.submenuToggleTargets.forEach((toggle) => {
      toggle.classList.remove("active");
    });
    if (immediate) {
      this.submenuTargets.forEach((submenu) => {
        gsap.set(submenu, { opacity: 0, display: "none" });
        submenu.classList.remove("fade-in--manual", "fade-in-childs--manual");
      });
      gsap.set(this.wrapperTarget, { height: this.mainMenuTarget.offsetHeight });
    } else {
      this.submenuTargets.forEach((submenu) => {
        gsap.to(submenu, { delay: 0, duration: 0.1, opacity: 0 });
        gsap
          .to(submenu, {
            delay: 0.1,
            display: "none",
            duration: 0,
          })
          .then(() => submenu.classList.remove("fade-in--manual", "fade-in-childs--manual"));
      });
      gsap.to(this.wrapperTarget, { delay: 0.1, duration: 0.25, height: this.mainMenuTarget.offsetHeight });
    }
    gsap.to(this.backgroundTarget, { delay: 0.1, display: "none", duration: 0 });
    this.currentSubmenu = null;
    this.currentSubmenuToggle = null;
  }
  toggleSubmenu(event = null) {
    let oldSubmenu = this.currentSubmenu;
    let oldSubmenuToggle = this.currentSubmenuToggle;

    this.currentSubmenu = null;
    this.currentSubmenuToggle = null;

    if (event != null) {
      this.submenuTargets.forEach((submenu) => {
        if (submenu.dataset.submenu == event.currentTarget.dataset.openSubmenu) {
          this.currentSubmenu = submenu == oldSubmenu ? null : submenu;
          this.currentSubmenuToggle = submenu == oldSubmenu ? null : event.currentTarget;
        }
      });
    }

    if (oldSubmenuToggle) {
      oldSubmenuToggle.classList.remove("active");
    }

    if (!this.currentSubmenu) {
      gsap.to(this.wrapperTarget, { delay: 0.1, duration: 0.25, height: this.mainMenuTarget.offsetHeight });
      if (oldSubmenu) {
        gsap.to(oldSubmenu, { delay: 0, duration: 0.1, opacity: 0 });
        gsap
          .to(oldSubmenu, {
            delay: 0.1,
            display: "none",
            duration: 0,
          })
          .then(() => oldSubmenu.classList.remove("fade-in--manual", "fade-in-childs--manual"));
      }
      gsap.to(this.backgroundTarget, { delay: 0.1, display: "none", duration: 0 });
    } else {
      this.currentSubmenuToggle.classList.add("active");
      gsap.to(this.backgroundTarget, { delay: 0, display: "block", duration: 0 });
      gsap.to(this.currentSubmenu, { delay: 0, display: "block", duration: 0 });
      gsap.to(this.wrapperTarget, {
        duration: 0.25,
        height: this.mainMenuTarget.offsetHeight + this.currentSubmenu.offsetHeight,
      });
      if (oldSubmenuToggle) {
        gsap.to(oldSubmenu, { delay: 0, duration: 0.15, opacity: 0, zIndex: 1 });
        gsap
          .to(oldSubmenu, {
            delay: 0.15,
            display: "none",
            duration: 0,
          })
          .then(() => oldSubmenu.classList.remove("fade-in--manual", "fade-in-childs--manual"));
        gsap.to(this.currentSubmenu, { delay: 0.15, duration: 0.15, opacity: 1, zIndex: 2 });
        gsap.delayedCall(0.01, () => this.currentSubmenu.classList.add("fade-in--manual", "fade-in-childs--manual"));
      } else {
        gsap.to(this.currentSubmenu, { delay: 0.15, duration: 0.15, opacity: 1, zIndex: 2 });
        gsap.delayedCall(0.01, () => this.currentSubmenu.classList.add("fade-in--manual", "fade-in-childs--manual"));
      }
    }
  }
}
