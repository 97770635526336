import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";

import { PlyrLayout, VidstackPlayer } from "vidstack/global/player";
import "vidstack/player/styles/base.css";
import "vidstack/player/styles/plyr/theme.css";

// Connects to data-controller="vidstack"
export default class extends Controller {
  static targets = ["container"];

  getSrc(video) {
    return this.element.dataset.source == "file" ? video : this.element.dataset.source + "/" + video;
  }

  getOrientatedSrc() {
    if (!this.element.dataset.videoPortrait) {
      return this.getSrc(this.element.dataset.video);
    }

    if (window.matchMedia("screen and (orientation: portrait)").matches) {
      return this.getSrc(this.element.dataset.videoPortrait);
    }

    return this.getSrc(this.element.dataset.video);
  }

  connect() {
    this.promise = VidstackPlayer.create({
      target: this.containerTarget,
      src: this.getOrientatedSrc(),

      playsinline: true,
      controls: false,
      muted: !!this.element.dataset.muted,
      autoPlay: !!this.element.dataset.autoplay,
      loop: !!this.element.dataset.loop,
      layout: this.element.dataset.layoutless ? undefined : new PlyrLayout(),
    });
  }

  disconnect() {
    this.promise?.then((player) => player.destroy());
    this.promise = undefined;
  }

  onPosterClick(event) {
    this.promise?.then((player) => player.play());
    gsap.to(event.currentTarget, { display: "none", duration: 0.5, opacity: 0 });
  }
}
